@import "~quill/dist/quill.snow.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
:root {
  --primary-color: #c820b2;
  --primary-color-light: #c21cab;
  --grey-color: #708090;
  --pink-color: #fff6fe;
  --warn-color: #ff0000;
}
html,
body {
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  margin: 0;
  font-size: 1.6rem;
  font-family: "Lato", sans-serif;
  color: var(--grey-color);
}
.primary-color {
  color: var(--primary-color);
}
.grey-color {
  color: var(--grey-color);
}
.warn-color {
  color: var(--warn-color);
}
.pink-bg {
  background-color: var(--pink-color);
}
.h--100 {
  height: 100%;
}
.w--100 {
  width: 100%;
}
// material component styles
.mat-raised-button {
  position: relative;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-out;
  padding: 0.2rem 3.2rem !important;
  box-shadow: 0px 6px 12px #00000029 !important;
  &:hover {
    box-shadow: 0px 8px 24px #00000043 !important;
  }
}
.mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0;
}
.mat-dialog-container {
  border-radius: 1.2rem !important;
}
.mat-form-field-infix {
  width: 260px !important;
}
.search-field {
  width: 50rem;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-color);
}
.text-center {
  text-align: center;
}
// Font Classes
.f-900 {
  font-weight: 900;
}
.f-700 {
  font-weight: 700;
}
.f-400 {
  font-weight: 400;
}
.f-300 {
  font-weight: 300;
}
.fs-12 {
  font-size: 1.2rem;
}
.fs-14 {
  font-size: 1.4rem;
}
.fs-16 {
  font-size: 1.6rem;
}
.fs-18 {
  font-size: 1.8rem;
}
.fs-20 {
  font-size: 2rem;
}
.fs-22 {
  font-size: 2.2rem;
}
.fs-24 {
  font-size: 2.4rem;
}
.fs-28 {
  font-size: 2.8rem;
}
.fs-30 {
  font-size: 3rem;
}
// Flex Classes
.d--f {
  display: flex;
}
.f--g-1 {
  gap: 1rem;
}
.f--g-2 {
  gap: 2rem;
}
.f--g-3 {
  gap: 3rem;
}
/* Flex direction */
.fd--r {
  flex-direction: row;
}
.fd--c {
  flex-direction: column;
}
/* Justify content */
.jc--fe {
  justify-content: flex-end;
}
.jc--fs {
  justify-content: flex-start;
} /* Default */
.jc--c {
  justify-content: center;
}
.jc--sa {
  justify-content: space-around;
}
.jc--sb {
  justify-content: space-between;
}
.jc--se {
  justify-content: space-evenly;
}
.ac--sb {
  align-content: space-between;
}
/* Align items */
.ai--fs {
  align-items: flex-start;
}
.ai--c {
  align-items: center;
}
.ai--fe {
  align-items: flex-end;
}
.ai--s {
  align-items: stretch;
} /* Default */
.ai--b {
  align-items: baseline;
}
/* Align self */
.as--c {
  align-self: center;
}
// flex for items
.f--1 {
  flex: 1;
}
.f--2 {
  flex: 2;
}
.fw-w {
  flex-wrap: wrap;
}
// margin and padding classes
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.m-4 {
  margin: 4rem;
}
.m-5 {
  margin: 5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mr-0 {
  margin-right: 0.5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}
.mr-5 {
  margin-right: 5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}
.ml-5 {
  margin-left: 5rem;
}
// Padding Helper CSS
.p-0 {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.p-4 {
  padding: 4rem;
}
.p-5 {
  padding: 5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pt-4 {
  padding-top: 4rem;
}
.pt-5 {
  padding-top: 5rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}
.pr-3 {
  padding-right: 3rem;
}
.pr-4 {
  padding-right: 4rem;
}
.pr-5 {
  padding-right: 5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pb-4 {
  padding-bottom: 4rem;
}
.pb-5 {
  padding-bottom: 5rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-3 {
  padding-left: 3rem;
}
.pl-4 {
  padding-left: 4rem;
}
.pl-5 {
  padding-left: 5rem;
}
.pointer {
  cursor: pointer;
}
.l-spacing {
  letter-spacing: 0.125rem;
}
.bb-1 {
  border-bottom: 1px solid #dddedf;
}
